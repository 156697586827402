<template>
  <div class="row">
    <!-- basic modal -->
    <b-modal
      id="modal-1"
      title="تثبيت حالة الفحص"
      ok-only
      ok-title="حسناً، شكراً لك"
    >
      <div
        class="alert alert-danger text-center"
        v-if="!accepted_pin"
        style="padding: 20px"
      >
        في حالة تثبيت حالة الفحص سوف يتم اعتبار اي تقرير يتم فحصه انه بنفس
        الحالة المُختارة.
        <br />
        <span class="text-primary"
          ><u>مثال:</u> اذا اخترت تثبيت الحالة الى حضور، فاذا قام المعلم بالفحص
          وهو متأخر او انصراف فسيُعتبر حاضر.</span
        >
        <br />
        <br />
        <button class="btn btn-success" @click="accepted_pin = true">
          حسناً فهمت
        </button>
      </div>
      <div v-if="accepted_pin">
        <strong>تثبيت الحالة إلى:</strong>
        <div class="form-check">
          <label class="form-check-label">
            <input
              type="radio"
              class="form-check-input"
              v-model="pinned"
              :value="false"
            />
            تلقائي
          </label>
        </div>
        <div class="form-check">
          <label class="form-check-label">
            <input
              type="radio"
              class="form-check-input"
              v-model="pinned"
              value="1"
            />
            حضور
          </label>
        </div>
        <div class="form-check">
          <label class="form-check-label">
            <input
              type="radio"
              class="form-check-input"
              v-model="pinned"
              value="2"
            />
            تأخر
          </label>
        </div>
        <div class="form-check">
          <label class="form-check-label">
            <input
              type="radio"
              class="form-check-input"
              v-model="pinned"
              value="4"
            />
            انصراف
          </label>
        </div>
      </div>
    </b-modal>
    <div class="col-12 col-lg-6 c">
      <h1 class="text-center g">
        {{ f }}
      </h1>
      <div class="card card-body">
        <img
          src="../../assets/images/loading.svg"
          style="width: 60px; height: 60px; margin: 0px auto"
          alt=""
          v-if="loading"
        />
        <div class="form-group">
          <label for="">رقم الهوية</label>
          <input
            type="text"
            class="form-control"
            ref="number"
            placeholder="اكتب رقم الهوية هنا.."
          />
        </div>
        <div class="col-12 text-center g">
          <button class="btn btn-primary" @click="scan()" v-if="noBTN">
            فحص المعلم
          </button>
          <div v-if="!noBTN">
            <h4 class="text-success">
              قم بالفحص الآن بإستخدام الباركود
              <i class="text-info"><br />(بإستخدام بطاقة الهوية)</i>
            </h4>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 text-center">
      <button class="btn btn-sm btn-link" @click="hhide()">
        X اخفاء القائمة اليمنى والعلوية
      </button>
    </div>
    <div class="col-12 col-lg-8 c">
      <div class="card">
        <div class="card-header">
          التقارير المحفوظة
          <button class="btn btn-success" @click="upload()">
            رفع التقارير
          </button>
        </div>
        <div class="card-body">
          <div class="col-12 table-responsive">
            <table class="table table-hover table-striped">
              <thead>
                <th>المعلم</th>
                <th>الحالة</th>
                <th>التوقيت</th>
              </thead>
              <tbody>
                <tr v-for="report in reports" v-bind:key="report.k">
                  <td>
                    {{ report.teacher.name }}
                  </td>
                  <td>
                    <span
                      class="badge badge-secondary"
                      v-if="report.status == 0"
                      >{{ report.status_text }}</span
                    >
                    <span
                      class="badge badge-success"
                      v-if="report.status == 1"
                      >{{ report.status_text }}</span
                    >
                    <span
                      class="badge badge-warning"
                      v-if="report.status == 2"
                      >{{ report.status_text }}</span
                    >
                    <span
                      class="badge badge-danger"
                      v-if="report.status == 3"
                      >{{ report.status_text }}</span
                    >
                    <span
                      class="badge badge-primary"
                      v-if="report.status == 4"
                      >{{ report.status_text }}</span
                    >
                    <small v-if="report.status == 2">
                      <br />
                      متأخر {{ report.late_time }} دقيقة
                    </small>
                  </td>
                  <td>
                    {{ report.time }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BCard, BCardText, BLink } from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import moment from "moment-timezone";

const axios = require("axios");
export default {
  components: {
    BCard,
    BCardText,
    BLink,
  },
  data() {
    return {
      f: JSON.parse(localStorage.getItem("user")).name,
      user: JSON.parse(localStorage.getItem('user')),
      teachers: [],
      groups: [],
      reports: [],
      loading: true,
      noBTN: true,
      accepted_pin: false,
      pinned: false,
    };
  },
  methods: {
    scan() {
      moment.tz.setDefault("Asia/Riyadh");
      var _g = this;
      if (
        this.$refs.number.value != "" &&
        this.$refs.number.value != undefined
      ) {
        var number = this.$refs.number.value.trim();
        var n = null;
        _g.teachers.forEach((teacher) => {
          if (number.trim() == teacher.number) {
            n = teacher;
          }
        });
        if (n == null) {
          _g.$toast({
            component: ToastificationContent,
            props: {
              title: "رقم الهوية غير صحيح",
              icon: "WarningIcon",
              variant: "danger",
            },
          });
          if (window.location.href.includes("barcode")) {
            _g.$refs.number.value = "";
          }
        } else {
          var group = [],
            now = moment().tz("Asia/Riyadh").format("YYYY-MM-DD H:mm"),
            today = moment().tz("Asia/Riyadh").format("YYYY-MM-DD");
          _g.groups.forEach((g) => {
            if (n.settings_group == g._id) {
              group = g;
            }
          });
          var arr = localStorage.getItem("reports");
          if (arr == null) {
            arr = [];
          }
          if (typeof arr == "string") {
            arr = JSON.parse(arr);
          }
          var add = true,
            aaa = false;
          arr.forEach(function (a) {
            if (a.number == n.number && a.date == today && a.status != 4) {
              add = false;
            }
          });

          var days1 = [
            "Sunday",
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday",
          ];
          var d1 = new Date();
          var dayName1 = days1[d1.getDay()];
          if (!group.days.includes(dayName1)) {
            add = false;
            aaa = true;
          }

          if (add) {
            arr.push({
              from: window.location.href.includes("barcode") ? 'barcode' : 'manual',
              k: Math.random(),
              number: n.number,
              teacher: n,
              time: now,
              date: today,
              settings: group,
              status: _g.statusBySettingsGroup(now, today, group, false),
              status_text: _g.statusBySettingsGroup(now, today, group, true),
              ex: "no",
              late_time: Math.abs(
                moment(
                  moment(today + " " + group.late_date),
                  "YYYY-MM-DD H:mm"
                ).diff(moment(), "minutes")
              ),
            });
            localStorage.setItem("reports", JSON.stringify(arr));
            _g.$toast({
              component: ToastificationContent,
              props: {
                title:
                  " [" +
                  _g.statusBySettingsGroup(now, today, group, true) +
                  "] " +
                  n.name,
                icon: "CheckIcon",
                variant: "success",
              },
            });
          } else {
            if (aaa) {
              _g.$toast({
                component: ToastificationContent,
                props: {
                  title: "اليوم غير مسموح به في الاعدادات",
                  icon: "WarningIcon",
                  variant: "warning",
                },
              });
            } else {
              _g.$toast({
                component: ToastificationContent,
                props: {
                  title: "تم التسجيل من قبل",
                  icon: "WarningIcon",
                  variant: "warning",
                },
              });
            }
            if (window.location.href.includes("barcode")) {
              _g.$refs.number.value = "";
            }
          }
          _g.$refs.number.value = "";
          var arr = localStorage.getItem("reports");
          if (arr == null) {
            arr = [];
          }
          if (typeof arr == "string") {
            arr = JSON.parse(arr);
          }
          _g.reports = arr;
        }
      } else {
        _g.$toast({
          component: ToastificationContent,
          props: {
            title: "برجاء كتابة رقم الهوية",
            icon: "WarningIcon",
            variant: "danger",
          },
        });
      }
    },
    statusBySettingsGroup(time, day, group, text) {
      moment.tz.setDefault("Asia/Riyadh");
      var status = 0,
        g = this,
        status_text = "خارج وقت الدوام";
      if (
        moment(time).tz("Asia/Riyadh").unix() >=
        moment(day + " " + group.end_date)
          .tz("Asia/Riyadh")
          .unix()
      ) {
        (status = 4), (status_text = "انصراف");
      } else if (
        moment(time).tz("Asia/Riyadh").unix() >=
        moment(day + " " + group.ghiab_date)
          .tz("Asia/Riyadh")
          .unix()
      ) {
        (status = 3), (status_text = "غياب");
      } else if (
        moment(time).tz("Asia/Riyadh").unix() >=
        moment(day + " " + group.late_date)
          .tz("Asia/Riyadh")
          .unix()
      ) {
        (status = 2), (status_text = "تأخر");
      } else if (
        moment(time).tz("Asia/Riyadh").unix() >=
        moment(day + " " + group.start_date)
          .tz("Asia/Riyadh")
          .unix()
      ) {
        (status = 1), (status_text = "حاضر");
      }
      if (g.pinned) {
        if (text) {
          if (g.pinned == "1") {
            return "حاضر";
          }
          if (g.pinned == "2") {
            return "تأخر";
          }
          if (g.pinned == "4") {
            return "انصراف";
          }
        } else {
          return g.pinned;
        }
      } else {
        if (text) {
          return status_text;
        } else {
          return status;
        }
      }
    },
    hhide() {
      document.querySelector(
        "#app > div > div.main-menu.menu-fixed.menu-accordion.menu-shadow.expanded.menu-light"
      ).style.display = "none";
      document.querySelector("#app > div > nav").style.display = "none";
      document.querySelector(
        "#app > div > div.app-content.content > div.content-wrapper > div.row.content-header > div > div > div"
      ).style.display = "none";
    },
    upload() {
      var _g = this;
      var arr = localStorage.getItem("reports");
      if (arr == null) {
        arr = [];
      }
      if (typeof arr == "string") {
        arr = JSON.parse(arr);
      }
      this.loading = true;
      axios
        .post(api + "/user/teachers/reports/upload", {
          jwt: _g.user.jwt,
          reports: arr,
        })
        .then(function (r) {
          if (r.data.status == 100) {
            localStorage.removeItem("reports");
            _g.reports = [];
            _g.loading = false;
          } else {
            _g.loading = false;
            _g.$toast({
              component: ToastificationContent,
              props: {
                title: "حدث خطأ اثناء الرفع 2",
                icon: "WarningIcon",
                variant: "danger",
              },
            });
          }
        })
        .catch(function (e) {
          console.log(e);
          _g.loading = false;
          _g.$toast({
            component: ToastificationContent,
            props: {
              title: "حدث خطأ اثناء الرفع",
              icon: "WarningIcon",
              variant: "danger",
            },
          });
        });
    },
  },
  created() {

      if(!checkPer("teachers|scan|manual") && !checkPer("teachers|scan|barcode")){
          this.$router.push('/NotPermitted')
          return false;
      }
    moment.tz.setDefault("Asia/Riyadh");
    var _g = this;
    setInterval(() => {
      _g.upload();
    }, 120000);
    var arr = localStorage.getItem("reports");
    if (arr == null) {
      arr = [];
    }
    if (typeof arr == "string") {
      arr = JSON.parse(arr);
    }
    this.reports = arr;
    axios
      .post(api + "/user/teachers/groups", {
        jwt: _g.user.jwt,
      })
      .then(function (r) {
        if (r.data.status == 200) {
          _g.$toast({
            component: ToastificationContent,
            props: {
              title: "حدث خطأ JWT",
              icon: "WarningIcon",
              variant: "danger",
            },
          });
        } else {
          _g.groups = r.data.response;
          axios
            .post(api + "/user/teachers/list", {
              jwt: _g.user.jwt
            })
            .then(function (r) {
              if (r.data.status == 200) {
                _g.$toast({
                  component: ToastificationContent,
                  props: {
                    title: "حدث خطأ JWT",
                    icon: "WarningIcon",
                    variant: "danger",
                  },
                });
              } else {
                _g.teachers = r.data.response;
                _g.loading = false;
              }
            })
            .catch(function () {
              _g.$toast({
                component: ToastificationContent,
                props: {
                  title: "حدث خطأ",
                  icon: "WarningIcon",
                  variant: "danger",
                },
              });
            });
        }
      })
      .catch(function () {
        _g.$toast({
          component: ToastificationContent,
          props: {
            title: "حدث خطأ",
            icon: "WarningIcon",
            variant: "danger",
          },
        });
      });
    if (window.location.href.includes("barcode")) {
      this.noBTN = false;
      setInterval(() => {
        if (
          typeof this.$refs.number != undefined &&
          this.$refs.number != undefined
        ) {
          this.$refs.number.focus();
          if (
            this.$refs.number.value != "" &&
            this.$refs.number.value != undefined
          ) {
            this.scan();
          }
        }
      }, 1000);
    }
  },
};
</script>

<style>
.pin {
  position: fixed;
  top: 100px;
  right: 10px;
}
.pin img {
  width: 30px;
}
.pin img:hover {
  border-radius: 50%;
  background: #fff;
  border: solid 2px goldenrod;
  cursor: pointer;
}
</style>
